import React from "react";
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {Grid} from '@mui/material';
import contact1 from "../contactImg/francois-colin.webp";
import contact2 from "../contactImg/nicolas-bochereau.webp";

// Style
const useStyles = makeStyles(theme => ({
	colorEspelia:{
		color:'#424242',
		textAlign:'left',
		"& div" : {
			fontSize : 12
		},
	},
	image : {
		width : 220,
		height : "auto",
		borderRadius: "90%"
	},
}));

export default function Contact() {
  	const classes = useStyles();
	const theme = useTheme();
	const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('xs')); 

	return (
		<Grid container direction="row" justifyContent="center" spacing={1}>
			<Grid item container direction="column" xs={12} sm={12} md={12} lg={6}>
			<Grid item xs={12} sm={12} md={12} lg={12}>
					<img src={contact1} alt="François COLIN" title="François COLIN" className={classes.image} />
					<div style={{marginTop: "2vh"}}>
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 25, fontWeight: "bold", color: "#f7ad0d", margin: 0}}>François COLIN</p>
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 18, fontWeight: "light", margin: 0}}>Chargé de développement commercial</p><br />
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 18, fontWeight: "bold", margin: 0,  marginBottom: "5vh"}}>
							<a href="mailto:francois.colin@espelia.fr" style={{textDecoration: "none", color: "white"}}>francois.colin@espelia.fr</a> 
							<br />
							<a style={{textDecoration:"none", color: "white"}} href="tel:0612945733">06 12 94 57 33</a>
						</p>
					</div>
				</Grid>
			</Grid>    
			<Grid item container direction="column" xs={12} sm={12} md={12} lg={6}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<img src={contact2} alt="Nicolas BOCHEREAU" title="Nicolas BOCHEREAU" className={classes.image} />
					<div style={{marginTop: "2vh"}}>
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 25, fontWeight: "bold", color: "#f7ad0d", margin: 0}}>Nicolas BOCHEREAU</p>
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 18, fontWeight: "light", margin: 0}}>Responsable produits</p><br />
						<p style={{fontSize: responsiveDeviceMobile ? 20 : 18, fontWeight: "bold", margin: 0, marginBottom: "5vh"}}>
							<a href="mailto:nicolas.bochereau@espelia.fr" style={{textDecoration: "none", color: "white"}}>nicolas.bochereau@espelia.fr</a> 
							<br />
							<a style={{textDecoration:"none", color: "white"}} href="tel:0678178915">06 78 17 89 15</a>
						</p>
					</div>
				</Grid> 
			</Grid>    
		</Grid>

	);
}
